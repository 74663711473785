<template>
  <div class="table m-0">
    <tr>
      <td>
        {{ subjectData.subject_code }}
      </td>
      <td>
        {{ subjectData.group }}
      </td>
      <td>
        {{ helpers.getLocalizedField(subjectData, 'subject') }}
      </td>
      <td>
        {{ subjectData.StudECTS }}
      </td>
      <td>
        {{ subjectData.Eval }}
      </td>
      <td>
        {{ subjectData.Total }}
      </td>
      <td>
        <span v-html="helpers.getSubjectIcon(subjectData.Eval)"></span>
      </td>
    </tr>
  </div>
</template>

<script>
  export default {
    name: 'SubjectHeadingNew',
    props: ['subjectData']
  };
</script>

<style scoped>

</style>
