<template>
  <b-modal
    @shown="shown"
    id="evaluation-details"
    :hide-header="true"
    :hide-footer="true"
    content-class="modal-blue"
    :title="$t('evaluations.details.title')"
    centered >
    <table class="table">
      <thead>
        <tr>
          <th> {{ $t('evaluations.details.lecturer') }}</th>
          <th> {{ $t('evaluations.details.category') }}</th>
          <th> {{ $t('evaluations.details.point') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="detail of details">
          <td>
            {{ helpers.getLocalizedField(detail, 'pers') }}
          </td>
          <td>
            {{ helpers.getLocalizedField(detail, 'category') }}
          </td>
          <td>
            {{ detail.point }}
          </td>
        </tr>
      </tbody>
    </table>
  </b-modal>
</template>

<script>
  import axios from 'axios';

  export default {
    name: 'evaluation-details',

    props: ['registration-id', 'subject-calendar-default-id'],

    data() {
      return {
        details: []
      }
    },

    methods: {
      shown() {
        this.details = [];
        this.loadEvaluationDetails();
        this.$emit('shown');
      },

      hide() {
        this.$emit('hide');
      },

      loadEvaluationDetails() {
        axios.get('/api/evaluations/details/' + this.registrationId + '/' + this.subjectCalendarDefaultId)
        .then(response => {
          this.details = response.data.data;
        })
        .catch(e => {
          this.helpers.notifyErrorMessage(e)
        })
      }
    }
  };
</script>

<style scoped>

</style>
