<template>
  <div class="container-fluid">
    <div class="row">
      <sidebar></sidebar>
      <nav-header></nav-header>
      <!-- MAIN CONTENT start -->
      <main role="main" class="col-12 col-sm-9 col-md-10 ml-sm-auto col-lg-10" >
        <breadcrumb :items="pageData.breadcrumb"></breadcrumb>

        <section class="px-0 mx-0 px-lg-5 mx-lg-5  mt-0 mt-md-5">
          <div class="container-fluid ">
            <div class="row justify-content-start mb-5 mt-md-5 mt-lg-0 pt-5 pt-sm-0">
              <div class="col-12 px-0 px-sm-3">
                <div v-if="generalInfo">
                  <span v-if="generalInfo.gpa_total" class="badge badge-primary mt-2">{{ $t('evaluations.gpa-total') }}: {{ generalInfo.gpa_total }}</span>
                  <span v-if="generalInfo.last_year_gpa" class="badge badge-primary mt-2">{{ $t('evaluations.last-year-gpa') }}: {{ generalInfo.last_year_gpa }}</span>
                  <span v-if="generalInfo.last_semester_gpa" class="badge badge-primary mt-2">{{ $t('evaluations.last-semester-gpa') }}: {{ generalInfo.last_semester_gpa }}</span>
                  <span class="badge badge-primary mt-2">{{ $t('evaluations.ects') }}: {{ generalInfo.ects }}</span>
                </div>
                <sk-list v-else :height="0.02" :items-count="1"></sk-list>
              </div>
              <div class="col-12 mt-4 pt-1  px-0 px-sm-3">
                <div class="d-inline-block bg-white-88 border-round py-md-0 py-lg-2 px-3 px-sm-4">
                  <div class="d-flex align-items-center justify-content-between py-3 py-sm-2 px-0 px-sm-3 mx-1">
                    <div class="d-flex align-items-center mr-4 pr-2">
                      <img src="/static/images/icons/evaluations/accept.png" alt="accepted evaluation" class="img-fluid mr-2 mr-sm-3 p-0 p-sm-1 ic">
                      <span class="s-b-1">{{ $t('evaluations.accepted') }}</span>
                    </div>
                    <div class="d-flex align-items-center mr-4 pr-2">
                      <img src="/static/images/icons/evaluations/fail.png" alt="failed evaluation" class="img-fluid mr-2 mr-sm-3 p-0 p-sm-1 ic">
                      <span class="s-b-1">{{ $t('evaluations.failed') }}</span>
                    </div>
                    <div class="d-flex align-items-center">
                      <img src="/static/images/icons/evaluations/recover.png" alt="recover evaluation" class="img-fluid mr-2 mr-sm-3 p-0 p-sm-1 ic">
                      <span class="s-b-1">{{ $t('evaluations.recover') }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="evaluations-section px-sm-0 mx-sm-0 px-lg-5 mx-lg-5 mt-0 mt-md-5">
          <div class="container-fluid">
            <div class="row justify-content-start mb-5 mt-md-5 mt-lg-0 pt-5 pt-sm-0">
              <div class="col-12  px-0 px-sm-3">

                <!-- evaluations -->
                <div class="accordion" id="semesters">
                  <evaluation-details
                    :registration-id="detailModalData.registrationId"
                    :subject-calendar-default-id="detailModalData.subjectCalendarDefaultId"
                    @hide="hideDetails" ref="evaluation-details"></evaluation-details>
                  <!-- Current semester -->
                  <!-- to current semester add class .current to card -->
                  <sk-list height="0.04" :items-count="1" v-if="!semesters || semesters.length === 0"></sk-list>
                  <div v-for="(semester, i) of semesters"
                       v-if="i === 0"
                       :class="['card', 'current', { active: chosenSemester === i }]">
                    <div class="card-header" id="currentSemesterHeading" >
                      <h2 class="mb-0">
                        <button @click="toggleSemester(i)" class="btn btn-registration" type="button" >
                          {{ semester.study_year + ' - ' + helpers.getLocalizedField(semester, 'period') }}
                          <span class="toggle-icon"></span>
                        </button>
                      </h2>
                    </div>

                    <!-- Current semester subjects -->
                    <b-collapse accordion="semester-accordion" @shown="onSemesterCollapsed(i)" :id="'semester-'+i" class="collapse" aria-labelledby="currentSemesterHeading" data-parent="#semesters">
                      <div class="card-body">
                        <div class="accordion accordion-inner" id="currentSemesterSubjects">
                          <div class="card p-4" v-if="!subjects || subjects.length === 0">
                            <sk-list height="0.03" :items-count="4"></sk-list>
                          </div>
                          <!-- subject 1 -->
                          <div v-else class="card" v-for="(subject, s) of subjects">
                            <div class="card-header" :id="'heading-subject_' + i + '-' +s">
                              <div @click="toggleSubject(i, s, subject)" class=" accordion-inner-btn">
                                <span class="toggle-icon"></span>
                                <div class="subject-info">
                                  <div class="fl">
                                    <div class="sm-col">
                                      <div>{{ subject.subject_code }}</div>
                                      <div>{{ helpers.getGroupNumber(subject.group) }}</div>
                                    </div>
                                    <div>{{ helpers.getLocalizedField(subject, 'subject') }}</div>
                                  </div>
                                  <div class="fr ic">
                                    <div>{{ subject.StudECTS }}</div>
                                    <div>{{ subject.Eval }}</div>
                                    <div>{{ subject.Total }}</div>
                                    <div>
                                      <img :src="helpers.getSubjectIcon(subject.Eval, subject.is_retake)" class="img-fluid">
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <b-collapse accordion="subject-accordion" @shown="onSubjectCollapsed(i, s, subject.registrationID)" :id="'subject-'+ i +'-'+ s" class="collapse" :aria-labelledby="'heading-subject_'+ i +'-' +s" data-parent="#currentSemesterSubjects">
                              <div class="card-body">
                                <sk-list v-if="!evaluations || evaluations.length === 0" height="0.01" :items-count="2"></sk-list>
                                <div class="outer">
                                  <div class="eval-card d-flex flex-column" v-for="(evaluation, e) of evaluations">
                                    <span>{{ helpers.getLocalizedField(evaluation, 'RatesType') }}</span>
                                    <div class="mt-auto">
                                      <span>{{ evaluation.week }} {{ $t('evaluations.week') }}</span>
                                      <hr>
                                      <span class="cursor-pointer" @click="showDetails(evaluation.evaluation)">{{ evaluation.evaluation ? evaluation.evaluation.qula : 0 }} {{ $t('evaluations.detailed') }}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </b-collapse>
                          </div>
                        </div>
                      </div>
                    </b-collapse>
                  </div>


                  <!-- Title -->
                  <div class="mt-5 mb-4 pt-4 mx-4 px-3  text-center">
                    <h1 class="pl-1 pt-2">{{ $t('evaluations.archive') }}</h1>
                  </div>

                  <sk-list height="0.04" :items-count="4" v-if="!semesters || semesters.length === 0"></sk-list>

                  <!--  Archive semesters -->

                  <!-- 1 semester -->
                  <div v-for="(semester, i) of semesters"
                       v-if="i > 0"
                       :class="['card', { active: chosenSemester === i }]">
                    <div class="card-header" :id="'heading'+i">
                      <h2 class="mb-0">
                        <button @click="toggleSemester(i)" class="btn btn-registration" type="button">
                          {{ semester.study_year + ' - ' + helpers.getLocalizedField(semester, 'period') }}
                          <span class="toggle-icon"></span>
                        </button>
                      </h2>
                    </div>

                    <!-- Current semester subjects -->
                    <b-collapse accordion="semester-accordion" @shown="onSemesterCollapsed(i)" :id="'semester-'+i" class="collapse" aria-labelledby="currentSemesterHeading" data-parent="#semesters">
                      <div class="card-body">
                        <div class="accordion accordion-inner" :id="'semester-'+i">
                          <div class="card " v-if="!subjects || subjects.length === 0">
                            <sk-list height="0.03" :items-count="4"></sk-list>
                          </div>
                          <!-- subject 1 -->
                          <div v-else class="card" v-for="(subject, s) of subjects">
                            <div class="card-header" :id="'heading-subject_' + i + '-' +s">
                              <div @click="toggleSubject(i, s, subject)" class=" accordion-inner-btn">
                                <span class="toggle-icon"></span>
                                <div class="subject-info">
                                  <div class="fl">
                                    <div class="sm-col">
                                      <div>{{ subject.subject_code }}</div>
                                      <div>{{ helpers.getGroupNumber(subject.group) }}</div>
                                    </div>
                                    <div class="sub-t">{{ helpers.getLocalizedField(subject, 'subject') }}</div>
                                  </div>
                                  <div class="fr">
                                    <div>{{ subject.StudECTS }}</div>
                                    <div>{{ subject.Eval }}</div>
                                    <div>{{ subject.Total }}</div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <b-collapse accordion="subject-accordion" @shown="onSubjectCollapsed(i, s, subject.registrationID)" :id="'subject-'+ i +'-'+ s" class="collapse" :aria-labelledby="'heading-subject_'+ i +'-' +s" data-parent="#currentSemesterSubjects">
                              <div class="card-body">
                                <sk-list v-if="!evaluations || evaluations.length === 0" height="0.01" :items-count="2"></sk-list>
                                <div class="outer">
                                  <div class="eval-card d-flex flex-column" v-for="(evaluation, e) of evaluations">
                                    <span>{{ helpers.getLocalizedField(evaluation, 'RatesType') }}</span>
                                    <div class="mt-auto">
                                      <span>{{ evaluation.week }} {{ $t('evaluations.week') }}</span>
                                      <hr>
                                      <span class="cursor-pointer" @click="showDetails(evaluation.evaluation)">{{ evaluation.evaluation ? evaluation.evaluation.qula : 0 }} {{ $t('evaluations.detailed') }}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </b-collapse>
                          </div>
                        </div>
                      </div>
                    </b-collapse>
                  </div>
                </div>
                <!-- evaluations -->

              </div>
            </div>
          </div>
        </section>


      </main>
      <!-- MAIN CONTETN end -->
    </div>
  </div>
</template>

<script>
  import sidebar from '../components/navbar/sidebar';
  import appHeader from '../components/app-header';
  import pageHeader from '../components/page/page-header';
  import { mapGetters } from 'vuex';
  import SubjectHeadingNew from '../components/evaluations/subject-heading-new';
  import EvaluationDetails from '../components/evaluations/evaluation-details';
  import SkList from '../components/skeletons/sk-list';
  import navHeader from '../components/navbar/nav-header';
  import breadcrumb from '../components/page/breadcrumb';


  export default {
    name: 'evaluations',

    components: { SkList, EvaluationDetails, SubjectHeadingNew, sidebar, appHeader, pageHeader, navHeader, breadcrumb },

    data() {
      return {
        chosenSemester: null,
        chosenSubject: null,
        subjects: [],
        evaluations: [],
        detailModalData: {
          registrationId: null,
          subjectCalendarDefaultId: null
        },
        generalInfo: null
      }
    },

    computed: {
      pageData() {
        return {
          title: this.$t('evaluations.title'),
          breadcrumb: [{
            icon: '',
            title: this.$t('home.title'),
            link: '/',
            isActive: false,
          },
            {
              icon: '',
              title: this.$t('evaluations.title'),
              link: '/evaluations',
              isActive: true,
            }],
        };
      },
      ...mapGetters({
        semesters: 'evaluation/semesters',
        currentSemester: 'semester/currentSemester'
      }),
    },

    mounted() {
      this.$store.dispatch('semester/loadCurrentSemester').then(() => {
        this.$store.dispatch('evaluation/loadSemesters');
      });
      this.$store.dispatch('evaluation/loadGeneralData').then(response => {
        this.generalInfo = response.data.data;
      });
    },

    methods: {
      showDetails(evaluation) {
        if(!evaluation) return;

        this.detailModalData.registrationId = evaluation.RegistrationID;
        this.detailModalData.subjectCalendarDefaultId = evaluation.subjects_calendar_defaultID;
        this.$bvModal.show('evaluation-details');
      },

      hideDetails() {
        this.detailModalData = {
          registrationId: null,
          subjectCalendarDefaultId: null
        }
      },

      toggleSemester(semesterIdx) {
        this.$root.$emit('bv::toggle::collapse', 'semester-'+semesterIdx);
      },

      toggleSubject(semesterIdx, subjectIdx, subject) {
        if(subject.group == 0) return;

        this.$root.$emit('bv::toggle::collapse', 'subject-' + semesterIdx + '-'+ subjectIdx);
      },

      onSemesterCollapsed(i) {
        this.subjects = [];
        this.chosenSemester = i;
        this.loadSubjects(i);
      },

      onSubjectCollapsed(semesterIdx, subjectIdx, registrationID) {
        this.evaluations = [];
        this.chosenSubject = subjectIdx;

        this.loadEvaluations(semesterIdx, subjectIdx, registrationID);
      },

      loadSubjects(semesterIdx) {
        return this.$store.dispatch('evaluation/loadSubjects', semesterIdx).then(resp => {
          this.subjects = resp.data.data;
        });
      },

      loadEvaluations(semesterIdx, subjectIdx, registrationID) {
        return this.$store.dispatch('evaluation/loadEvaluations', { semesterIdx, subjectIdx, registrationID }).then(resp => {
          this.evaluations = resp.data.data;
        });
      }
    }
  };
</script>

<style scoped>
  .fl{ flex-basis: 80%;}
  .fr{flex-basis: 20%; }
  .fr > div{
    margin-right: 0;
    flex-grow: 1;
    flex-basis: 0;
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
  }
  .fr.ic > div:last-child{
    justify-content: space-evenly;
    height: 100%;
    align-items: center;
  }
  .fr > div:last-child{
    justify-content: space-evenly;
    height: 100%;
  }
  .sm-col{flex-basis: 27%;}
  .sm-col div{
    margin-right: 0 !important;
    flex-basis: 50%;
  }
  .sub-t{ flex-basis: 55%;}
  @media screen and (min-width: 1200px){
    .btn-registration{
      padding-left: 2.25rem !important;
    }
  }
  @media screen and (max-width: 1200px){
    .btn-registration{
      padding-left: 3.25rem !important;
    }
  }
  @media screen and (max-width:576px){
    .btn-registration{
      padding-left: 1.5rem !important;
    }
  }
  @media screen and (max-width:1500px){
    .sm-col div{
      margin-right: 1rem !important;
    }
  }
  @media screen and (max-width:1200px){
    .fl{
      flex-basis: 72% ;
    }
    .fr{
      flex-basis: 28% ;
    }
    .fr.ic > div:last-child{
      align-items: flex-start;
      margin-top: 5px;
    }
  }
  @media screen and (max-width:576px){
    .fr.ic > div:last-child{
      margin-top: 2px;
    }
  }

</style>
